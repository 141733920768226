import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../../services/user.service';
import { PagerService } from '../../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-student',
  templateUrl: './batch.component.html',
  providers: [UserService,PagerService]
})
export class BatchComponent implements OnInit {
  public batchForm: FormGroup;
  public batches: any = new Array();
  public user : any;
  public response: any;
  public deletedSelected: any = new Array();
  public flag: string;
  private allItems: any[];
  row: any;
    // pager object
   pager: any = {};
   perPage:any = 10;

    // paged items
  pagedItems: any[];
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really <b>sure</b> you want to do this?';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  
  constructor(private _users: UserService,
              private  toastr: ToastrService,  
              private pagerService: PagerService
			 ) {}

  ngOnInit() {
	  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.getBatches();
	this.batchForm = new FormGroup ({
		 batch_name: new FormControl('', Validators.required),
		 status: new FormControl('', Validators.required),
	  });
  }
  
  
  
    getBatches(){
	  this._users.getBatches(this.user)
	 .subscribe(batches => { 
	       this.batches = batches;
           this.setPage(1);		   });
  
   }
  
    refresh(){
	  this.setPage(1);
    }
  
   setPage(page: number) {
			// get pager object from service
			this.pager = this.pagerService.getPager(this.batches.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.batches.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}
  onDelete(id){
         this.deletedSelected.push(id);
		 this.deleteSelected();
  }
  onSaveConfirm(data) {
	  data.user_id= this.user.id;
	    this._users.addBatch(data)
	   .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.toastr.success(this.response.message);
			  this.batchForm.reset();
			  this.getBatches();
			  this.show('list');
		  }
		  else{
			  this.toastr.error(this.response.message); 
		  }

	  })
  }
   //checkbox function
    checkbox(check, id) {
		
		if( check.target.checked)
		  {
		   this.deletedSelected.push(id);
		  }
		else{
			const index: number = this.deletedSelected.indexOf(id);
				if (index !== -1) {
					this.deletedSelected.splice(index, 1);
				}   
		}
    }	 
  deleteSelected(){
		if(this.deletedSelected.length > 0)
		{   
	        var data = {table: 'batches', id: this.deletedSelected };
			this._users.deleteRecords(data)
			.subscribe(response => {
			this.response = response;
			if(this.response)
			{
				this.toastr.success("Records have been deleted.");
			    this.getBatches();
				this.deletedSelected = new Array();
			}
			else{
				
				this.toastr.error("Something wrong, Please try again!");
			}
			});
			
		}
		else{
			this.toastr.error("Please select at list one record!")
		}
		
	}
	  show(flag){
		  this.flag = flag;
	   }
	edit(id){
		  var data = {table : 'batches', id : id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			this.batchForm.setValue({
						batch_name: this.row.batch_name,
						status: this.row.status
					});
		 this.flag = 'edit';		

		 });
	  }

	 onUpdate(data){
		   data.id = this.row.id;
		   data.table = 'batches';
		   this._users.updateData(data)
		   .subscribe(response => {
			  this.response = response;
			  if(this.response.success){
				  this.toastr.success(this.response.message);
				  this.batchForm.reset();
				  this.getBatches();
				  this.show('list');
			  }
			  else{
				  this.toastr.error(this.response.message); 
			  }
		  });
	  }
}
