import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-student',
  templateUrl: './foundation.component.html',
  providers: [UserService,PagerService]
})
export class FoundationComponent implements OnInit {
  public feeForm: FormGroup;
  public fees: any = new Array();
  public user : any;
  public response: any;
  public flag: string;
  
  constructor(private _users: UserService,
              private  toastr: ToastrService,  
              private pagerService: PagerService
			 ) {}

  ngOnInit() {
	  
	this.flag ='main';
	this.user =  JSON.parse(this._users.getUser());
	this.feeForm = new FormGroup ({
		 fee: new FormControl('', Validators.required),
		 duration: new FormControl('', Validators.required),
	  });
  }
  show(flag){
	 
	  this.flag = flag;
   }


}
