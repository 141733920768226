import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router }  from '@angular/router'; 

@Component({
  selector: 'app-ielts-listening',
  templateUrl: './ielts-listening.component.html',
  styleUrls: ['./ielts-listening.component.css']
})
export class IeltsListeningComponent implements OnInit {
  
  page:string;
  constructor( private _route: Router, private route:ActivatedRoute) { }

  ngOnInit() {
	  
	  this.route.params.subscribe(params => {
				   this.page =  params.page;
				});
  }

}
