import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router }  from '@angular/router'; 

@Component({
  selector: 'app-ielts-writing',
  templateUrl: './ielts-writing.component.html',
  styleUrls: ['./ielts-writing.component.css']
})
export class IeltsWritingComponent implements OnInit {

 page:string;
  constructor( private _route: Router, private route:ActivatedRoute) { }

  ngOnInit() {
	  
	  this.route.params.subscribe(params => {
				   this.page =  params.page;
				});
  }


}
