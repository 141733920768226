import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'front-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [UserService],
})
export class HeaderComponent implements OnInit {
  public isUserLoggedIn: boolean = false;
  public UserSession   : any = [];
  constructor(private _users: UserService){}

  ngOnInit() {
	  
	   if(this._users.isLogin())
		 {    
	          this.isUserLoggedIn = true;
			  this.UserSession = JSON.parse(this._users.getUser()); 
			
		 }
  }
	  
	  	
}


