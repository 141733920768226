import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderService } from './services/loader.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet><span *ngIf="showLoader" class="loading"></span></router-outlet>',
  styleUrls: ['./app.component.css'],
  
})
export class AppComponent implements OnInit {
	showLoader: boolean;
    constructor(private router: Router, private loaderService: LoaderService) { }

  ngOnInit() {
	   this.loaderService.status.subscribe((val: boolean) => {
            this.showLoader = val; 
        });
	  
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
