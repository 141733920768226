import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router }  from '@angular/router'; 

@Component({
  selector: 'app-ielts-resources',
  templateUrl: './ielts-resources.component.html',
  styleUrls: ['./ielts-resources.component.css']
})
export class IeltsResourcesComponent implements OnInit {

  page :string;
  constructor( private _router: Router, private route: ActivatedRoute) { }

     ngOnInit() {
	    	this.route.params.subscribe(params => {
				   this.page = params.page;
				  
				});
				
  }

}
