import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService } from '../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [UserService],
})
export class HomeComponent implements OnInit {
  
  public latestUpdates : any;
  
  
  
  constructor(private _users: UserService, private _router: Router ) { }
  

  ngOnInit() {
	  
	  this._users.getLatestUpdate()
	   .subscribe(latestUpdates => this.latestUpdates= latestUpdates );
  }

}
