import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { ExcelService } from '../../services/excel.service';
import { LoaderService } from '../../services/loader.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';

@Component({
  selector: 'app-student',
  templateUrl: './query.component.html',
  providers: [UserService,PagerService, DatePipe, FilterdataPipe]
})
export class QueryComponent implements OnInit {
  public queryForm: FormGroup;
  public settings : any;
  public data : any ;
  public queries: any = new Array();
  public courses: any = new Array();
  public user : any;
  public response: any;
  public deletedSelected: any = new Array();
  public flag: string;
  private allItems: any[];
  public filterData : any = new Array();

  row: any;
  name: string;
  father: string;
  mobile:string;
  city: string;
  date:string;
  status:number;  
  peopleFilter: any;  
    // pager object
   pager: any = {};
   perPage:any = 10;

    // paged items
  pagedItems: any[];
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really <b>sure</b> you want to do this?';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;

  
  constructor(private _users: UserService,
              private  toastr: ToastrService,  
              private pagerService: PagerService, 
			  public datepipe: DatePipe, 
              private pipe: FilterdataPipe,
			  private excelService: ExcelService,
			  public _loader: LoaderService) {}

  ngOnInit() {
	this._loader.display(true);  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.getQueries();
    this.getCourse();
	
	this.queryForm = new FormGroup ({
		 student_name: new FormControl('', Validators.required),
		 father_name: new FormControl(''),
		 mobile: new FormControl('', Validators.required),
		 address: new FormControl('', Validators.required),
		 city: new FormControl('', Validators.required),
		 date_of_query: new FormControl('', Validators.required),
		 query_for: new FormControl('', Validators.required),
		 reminder: new FormControl(''),
		 remark: new FormControl('')
		 
	  });
	 this._loader.display(false);  
  }
  
  
  
    getQueries(){
	  this.data = this._users.getQueries(this.user)
	 .subscribe(queries => { 
	       this.queries = queries;
		   this.filterData = this.queries; 
           this.setPage(1);		   });
  
   }
   
   getCourse(){
	   this._users.getCourse(this.user)
	 .subscribe(courses => { 
	       this.courses = courses;
           this.setPage(1); });
   }
  
    refresh(){
	  this.setPage(1);
    }
	
  
   setPage(page: number) {
			// get pager object from service
			this.pager = this.pagerService.getPager(this.filterData.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.filterData.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}

  
  onDelete(id){
         this.deletedSelected.push(id);
		 this.deleteSelected();
  }

  onSaveConfirm(data) {
	  this._loader.display(true);
	  data.user_id= this.user.id;
	  data.date_of_query = this.datepipe.transform(data.date_of_query, 'yyyy-M-dd')
	  data.reminder = this.datepipe.transform(data.reminder, 'yyyy-M-dd')
	    this._users.addQuery(data)
	   .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.queryForm.reset();
			  this._loader.display(false);
			  this.toastr.success(this.response.message);
			  this.getQueries();
			  this.show('list');
			  
		  }
		  else{
			  this._loader.display(false);
			  this.toastr.error(this.response.message);
			  
		  }

	  })
  }
  
   //checkbox function
    checkbox(check, id) {
		if( check.target.checked)
		  {
		   this.deletedSelected.push(id);
		  }
		else{
			const index: number = this.deletedSelected.indexOf(id);
				if (index !== -1) {
					this.deletedSelected.splice(index, 1);
				}   
		}
    }	
	 
  deleteSelected(){
	    
		if(this.deletedSelected.length > 0)
		{   this._loader.display(true);
	        var data = {table: 'query', id: this.deletedSelected, query_status: 0 };
			this._users.updateData(data)
			.subscribe(response => {
			this.response = response;
			if(this.response.success)
			{   
		        this._loader.display(false);
				this.toastr.success("Records have been deleted.");
			    this.getQueries();
				this.deletedSelected = new Array();
			}
			else{
				this._loader.display(false);
				this.toastr.error("Something wrong, Please try again!");
			}
			});
			
		}
		else{
			this.toastr.error("Please select at list one record!")
		}
		
	}
  show(flag){
	  this.flag = flag;
  }
  
  searchData(){
	   this.filterData = this.pipe.transform(this.queries, this.peopleFilter, false);
	
       this.setPage(1);  
  }
  
  search(filter){
	  this.peopleFilter = {};
	  if(filter == 'name' )    this.peopleFilter = {'student_name': this.name };
	  if(filter == 'father' )  this.peopleFilter = {'father_name':  this.father };
	  if(filter == 'mobile' )  this.peopleFilter = {'mobile': this.mobile };
	  if(filter == 'city' )    this.peopleFilter = {'city': this.city };
	  if(filter == 'date')    { this.peopleFilter = {'date_of_query': (this.date !='') ? this.datepipe.transform(this.date, 'yyyy-M-d'): ''}; }
	  if(filter == 'status' )  this.peopleFilter = {'query_status': this.status };
	  this.searchData();
  }
  
  edit(id){
	      this._loader.display(true);
		  var data = {table : 'query', id : id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			this.queryForm.setValue({
						 student_name: this.row.student_name,
						 father_name: this.row.father_name,
						 mobile: this.row.mobile,
						 address: this.row.address,
						 city: this.row.city,
						 date_of_query: this.row.date_of_query,
						 query_for:this.row.query_for,
						 reminder: this.row.reminder,
						 remark: this.row.remark
						
					});
		 this._loader.display(false);			
		 this.flag = 'edit';		
         
		 });
	  }

	 onUpdate(data){
		   this._loader.display(true);
		   data.id = this.row.id;
		   data.table = 'query';
		   data.date_of_query = this.datepipe.transform(data.date_of_query, 'yyyy-M-dd')
	       data.reminder = this.datepipe.transform(data.reminder, 'yyyy-M-dd')
		   this._users.updateData(data)
		   .subscribe(response => {
			  this.response = response;
			  if(this.response.success){
				  this.queryForm.reset();
				  this._loader.display(false);
				  this.toastr.success(this.response.message);
				  this.getQueries();
				  this.show('list');
			  }
			  else{
				  this._loader.display(false);
				  this.toastr.error(this.response.message); 
			  }
		  });
	  }
	  
   exportToExcel() {
	         var ecxelData = new Array();
			 this.filterData.forEach(obj => {
				      var data = obj; 
				      delete data['id'];
                      delete data['user_id'];
					  delete data['admission'];
					 if(data['query_status'] == 1) { data['query_status'] = 'Mature'; } else  { data['query_status'] = 'Non Mature'; }
					 ecxelData.push(data);	  		  
			    });
			 this.getQueries();
				  
    this.excelService.exportAsExcelFile(ecxelData, 'query');
  }


	  keyPress(event: any) {
		const pattern = /[0-9\+\-\ ]/;

		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
		  event.preventDefault();
		}
	  }
  
     public exportToPdf() 
		 { 
		 var data = document.getElementById('contentToConvert'); 
		 html2canvas(data).then(canvas => { 
		 // Few necessary setting options 
		 var imgWidth = 208; 
		 var pageHeight = 295; 
		 var imgHeight = canvas.height * imgWidth / canvas.width; 
		 var heightLeft = imgHeight; 
		 
		 const contentDataURL = canvas.toDataURL('image/png') 
		 let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
		 var position = 0; 
		 pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
		 pdf.save('queries.pdf'); // Generated PDF  
		 }); 
	  }

  
  
}
