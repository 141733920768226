export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'User Management'
  },
   {
    name: 'Users',
    url: '/users',
    icon: 'icon-user',
    children: [
	   {
        name: 'Query',
        url: '/users/query',
        icon: 'icon-list'
      },
      {
        name: 'List',
        url: '/users/list',
        icon: 'icon-list'
      },
      {
        name: 'Add',
        url: '/users/add',
        icon: 'icon-user'
      },
	   {
        name: 'Students',
        url: '/users/students',
        icon: 'icon-user'
      }
	  ]
     },
	 
	 
	  {
    title: true,
    name: 'Test Management'
  },
  {
    name: 'Tests',
    url: '/tests',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'mock',
        url: '/tests/mock',
        icon: 'icon-puzzle'
      },
      {
        name: 'sample',
        url: '/tests/sample',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/tests/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tables',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    title: true,
    name: 'Questions Management'
  }, 
  {
    name: 'Questions',
    url: '/questions',
    icon: 'icon-puzzle',
    children: [
	  {
        name: 'Questions List',
        url: '/questions/list',
        icon: 'icon-puzzle'
      },
      {
        name: 'Add Listening',
        url: '/questions/listening',
        icon: 'icon-puzzle'
      },
      {
        name: 'Add Reading',
        url: '/questions/reading',
        icon: 'icon-puzzle'
      },
      {
        name: 'Add Speaking',
        url: '/questions/speaking',
        icon: 'icon-puzzle'
      },
      {
        name: 'Add Writing',
        url: '/questions/writing',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    title: true,
    name: 'Content Management'
  },
    {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Latest Update',
        url: '/pages/latest',
        icon: 'icon-puzzle'
      },
      {
        name: 'Blog',
        url: '/pages/blog',
        icon: 'icon-puzzle'
      },
      {
        name: 'Video Galary',
        url: '/pages/galary',
        icon: 'icon-puzzle'
      },
	  {
        name: 'Office Address',
        url: '/pages/address',
        icon: 'icon-puzzle'
      }
   
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  }
];
