import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router }  from '@angular/router'; 
import { ToastrService } from 'ngx-toastr';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';

@Component({
  selector: 'app-student',
  templateUrl: './receipt.component.html',
  providers: [UserService,PagerService, FilterdataPipe]
})
export class ReceiptComponent implements OnInit {
  public receiptForm: FormGroup;
  public response: any;
  public transaction: any = new Array();
  public student: any = new Array();
  public flag: string;
  public user : any;
  id: number;
  receipt_no : string;
  kit : number = 0;
  settings : any;
  currentdate : any = new Date();
  course: any = new Array();
  
  
  constructor(private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe, private route: ActivatedRoute) {}

  ngOnInit() {
	  
	this.flag ='num';
	this.user =  JSON.parse(this._users.getUser());
	this.route.params.subscribe(params => {
				   this.id = params.id;
				});
	this.getTransactionDetail();
    this.getsetting();	
	this.receiptForm = new FormGroup ({
		 name: new FormControl('', Validators.required),
		 father_name: new FormControl('', Validators.required),
		 phone: new FormControl('', Validators.required),
		 mobile: new FormControl(),
		 city: new FormControl('', Validators.required),
		 address: new FormControl('', Validators.required),
		 dob: new FormControl('', Validators.required),
		 joining_date: new FormControl('', Validators.required),
		 image: new FormControl()
	  });
  }
  
  
  
  getTransactionDetail(){
	  var data = {table : 'transaction', id : this.id };
	  this._users.getById(data)
	 .subscribe(transaction => { 
	   this.transaction = transaction;
	         if(this.transaction.receipt_no > 0) { this.receipt_no = this.transaction.receipt_no;  }
			 var sdata = {table : 'students', id :  this.transaction.student_id };	
			 this._users.getById(sdata)
			 .subscribe(student => { 
			   this.student = student; 
			   this.kit =  parseInt(this.student.kit);
			   var cdata = {table : 'course', id :  this.student.course };	
			    this._users.getById(cdata)
			    .subscribe(course => { 
			     this.course = course; 
			  });  
		 });  
	  });
  }
  
    getsetting(){
	   this._users.getSetting(this.user)
	 .subscribe(settings => {
	   this.settings = settings;
	 });
  
   }

  
    takeReceipt(){
		if(this.receipt_no !=='' && this.receipt_no !== undefined){
		 var data = {table: 'transaction', id: this.id, receipt_no : this.receipt_no };	
	     this._users.updateData(data)
	        .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.flag ='rect';
		  }
		  else{
			  
			  this.toastr.error(this.response.message); 
		  }

	       })
			
		}
		else{
			this.toastr.error('Please enter receipt number!'); 
		}
		
	}
  
	 print(): void {
			let printContents, popupWin;
			printContents = document.getElementById('receit_box').innerHTML;
			popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
			popupWin.document.open();
			popupWin.document.write(`
			  <html>
				<head>
				  <title>Print</title>
				 
				  <link rel="stylesheet" href="/assets/css/style.css">
				  <link rel="stylesheet" href="/assets/css/style.min.css">
				 <link rel="stylesheet" href="/assets/css/bootstrap.min.css">
				 <link rel="stylesheet" href="/assets/css/font-awesome.min.css">
                 <link rel="stylesheet" href="/assets/css/typography.css">
				
				</head>
			   <body onload="window.print();window.close()">${printContents}</body>
			  </html>`
			);
			popupWin.document.close();
		}
  
  
}
