import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../../services/user.service';
import { PagerService } from '../../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-student',
  templateUrl: './course.component.html',
  providers: [UserService,PagerService]
})
export class CourseComponent implements OnInit {
  public courseForm: FormGroup;
  public courses: any = new Array();
  public user : any;
  public response: any;
  public deletedSelected: any = new Array();
  public flag: string;
  private allItems: any[];
  row : any;
    // pager object
   pager: any = {};
   perPage:any = 10;

    // paged items
  pagedItems: any[];
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really <b>sure</b> you want to do this?';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  
  constructor(private _users: UserService,
              private  toastr: ToastrService,  
              private pagerService: PagerService
			 ) {}

  ngOnInit() {
	  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.getCourse();
	this.courseForm = new FormGroup ({
		 course_name: new FormControl('', Validators.required),
		 status: new FormControl('', Validators.required),
	  });
  }
  
  
  
    getCourse(){
	   this._users.getCourse(this.user)
	 .subscribe(courses => { 
	       this.courses = courses;
           this.setPage(1); });
   }
    
   setPage(page: number) {
			// get pager object from service
			this.pager = this.pagerService.getPager(this.courses.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.courses.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}
  onDelete(id){
         this.deletedSelected.push(id);
		 this.deleteSelected();
  }
  onSaveConfirm(data) {
	  data.user_id= this.user.id;
	    this._users.addCourse(data)
	   .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.courseForm.reset();
			  this.toastr.success(this.response.message);
			  this.getCourse();
			  this.show('list');
		  }
		  else{
			  this.toastr.error(this.response.message); 
		  }

	  });
  }
  
  deleteSelected(){
		if(this.deletedSelected.length > 0)
		{   
	        var data = {table: 'course', id: this.deletedSelected };
			this._users.deleteRecords(data)
			.subscribe(response => {
			this.response = response;
			if(this.response)
			{   
				this.toastr.success("Records have been deleted.");
			    this.getCourse();
				this.deletedSelected = new Array();
			}
			else{
				
				this.toastr.error("Something wrong, Please try again!");
			}
			});
			
		}
		else{
			this.toastr.error("Please select at list one record!")
		}
		
	}
  show(flag){
	  this.flag = flag;
   }
  
  
  edit(id){
	  var data = {table : 'course', id : id };
	  this._users.getById(data)
	 .subscribe(row =>{
		this.row = row;
        this.courseForm.setValue({
				    course_name: this.row.course_name,
				    status: this.row.status
				});
	 this.flag = 'edit';		

	 });
  }
  
  onUpdate(data){
	   data.id = this.row.id;
	   data.table = 'course';
	   this._users.updateData(data)
	   .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.toastr.success(this.response.message);
			  this.courseForm.reset();
			  this.getCourse();
			  this.show('list');
		  }
		  else{
			  this.toastr.error(this.response.message); 
		  }
	  });
  }
}
