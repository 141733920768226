import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { LoaderService } from '../../services/loader.service';
import { ExcelService } from '../../services/excel.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router }  from '@angular/router';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-student',
  templateUrl: './refund.component.html',
  providers: [UserService,PagerService, FilterdataPipe]
})
export class RefundComponent implements OnInit {
  public refundForm: FormGroup;
  public settings : any;
  public data : any;
  public students: any = new Array();
  public filterData: any = new Array();
  public user : any;
  public response: any;
  public deletedSelected: any = new Array();
  public flag: string;
  private allItems: any[];
  public student :any;
  public studentFee: any;
  public total_days : number;
  public day_start: any = new Date();
  public day_end :any;
  public start_date :any;
  public end_date :any;
  peopleFilter : any; 
    // pager object
   pager: any = {};
   perPage:any = 10;

    // paged items
  pagedItems: any[];
  row :any;
  id: number;
  constructor(private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe, private route: ActivatedRoute, public _loader: LoaderService, private excelService: ExcelService) {}

  ngOnInit() {
	  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.route.params.subscribe(params => {
				   this.id = params.id;
				});
				
				
    if(this.id != undefined)
	{
	 
		this.refundForm = new FormGroup ({
			   registration_no: new FormControl('', Validators.required),
			   name: new FormControl('', Validators.required),
			   amount: new FormControl('', Validators.required)
		
		  });
	  
	 this._loader.display(true);
		  var data = {table : 'transaction', id : this.id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			this.serchDetial(this.row);
			
		  this.flag = 'add';			
		  this._loader.display(false);	  
	  });
    }		
	this.getRefundData();
	
  }
  
  
  
    getRefundData(){
	  this._users.refundStudent(this.user)
	 .subscribe(students => { 
	       this.students = students;
		   this.filterData = this.students;
           this.setPage(1);
		   });
  
    }
	
    refresh(){
	  this.setPage(1);
    }
  
   setPage(page: number) {
			// get pager object from service
			
			this.pager = this.pagerService.getPager(this.students.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.students.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}

  onSaveConfirm(data) {
	   data.user_id = this.user.id;
	   data.student_id = this.student.id;
	   data.transaction_id = this.id;
	   data.receipt_amount = this.row.amount;
	   this._users.refundAmount(data)
	  .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.show('list');
			  this.getRefundData();
			  this.toastr.success(this.response.message); 
		  }
		  else{
			  
			  this.toastr.error(this.response.message); 
		  }
	  })
  }
  
  show(flag){
	  this.flag = flag;
  }
  
   serchDetial(row){
	  var data = {user_id : this.user.id, registration_no: row.registration_no };
	  this._users.getStudentByReg(data)
	 .subscribe(student => { 
	       this.student = student;
		   this.start_date = new Date(row.start_date);
		   this.end_date = new Date(row.end_date);
		   this.total_days = Math.round(( this.end_date - this.start_date) / (1000 * 60 * 60 * 24));
	       var fee = row.amount;
	       var dayfee = Math.round(fee/this.total_days); 
           this.day_end = new Date(row.start_date);
		   if(this.day_start > this.day_end) {
			 this.total_days = Math.round((this.day_start - this.day_end) / (1000 * 60 * 60 * 24));
		     var totalday =  this.total_days;
		     var amount = totalday* dayfee;  
			 var st_data = {id: this.student.id };  
             var refund  = 0;
					 refund = row.amount - amount; 
					 this.refundForm.setValue({
			             registration_no: this.student.registration_no,
						 name: this.student.name,
						 amount: refund					 
                    }); 	  
		     }
			 else{
				 this.refundForm.setValue({
			             registration_no: this.student.registration_no,
						 name: this.student.name,
						 amount: 0					 
                    }); 
			 }
		   }); 
  }
  
  public exportToExcel() {
				 var ecxelData = this.filterData; 
				 ecxelData.forEach(obj => {
					      delete obj['id'];
						  delete obj['user_id'];
						  delete obj['student_id'];
						  delete obj['status']; 
						  
				 });
				 this.getRefundData();
				 if(this.students.length > 0) {
				 this.excelService.exportAsExcelFile(ecxelData, 'refunded_list');
				 }
				 else {
					this.toastr.error("No record found.");
				}
	  }
  	 

    public exportToPdf() 
		 { 
				if(this.students.length > 0) {
				 var data = document.getElementById('contentToConvert'); 
				 html2canvas(data).then(canvas => { 
				 // Few necessary setting options 
				 var imgWidth = 208; 
				 var pageHeight = 295; 
				 var imgHeight = canvas.height * imgWidth / canvas.width; 
				 var heightLeft = imgHeight; 
				 
				 const contentDataURL = canvas.toDataURL('image/png') 
				 let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
				 var position = 0; 
				 pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
				 pdf.save('refunded_list.pdf'); // Generated PDF  
				 }); 
				}
				else {
					this.toastr.error("No record found.");
				}
		 }
  
}
