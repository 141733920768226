import { Injectable, Inject } from '@angular/core';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Http , Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { BASEURL } from '../config/config';

@Injectable()
export class AdminService {
   private _baseurl= BASEURL; 
   constructor(private _http: Http, public _localStorage: CoolLocalStorage){}
		 
			public setSession(user)
			 {
			   this._localStorage.setItem('admin', JSON.stringify(user));
			 }
			 
			public getSession()
			 {  
			   return this._localStorage.getItem('admin');
			 }
			 
		   login(user)
			{  

			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			
			   return this._http.post(this._baseurl+ 'admin/login', user, options)
						   .map((response: Response) =>  response.json());
			 }
		   getData(data){
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			  return this._http.post(this._baseurl+ 'admin/getData', data, options)
					   .map((response: Response) =>  response.json());
				}
		   getInstituteData(data){
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			  return this._http.post(this._baseurl+ 'admin/getInstituteData', data, options)
					   .map((response: Response) =>  response.json());
				}			
	   
		   insertdata(data){
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			  return this._http.post(this._baseurl+ 'insertdata', data, options)
					   .map((response: Response) =>  response.json());
				}
			
		   updatedata(data){
			 
			 let headers = new Headers ({ 'Content-Type' : 'application/json'});
			 let options = new RequestOptions({ headers: headers });
			 return this._http.post(this._baseurl + 'admin/updatedata', data, options)
						  .map((response : Response) => response.json());
			 
		    }		
		   
		   getCities() { 
			return this._http.get(this._baseurl+ 'getCities')
			.map((response: Response) =>  response.json());
		   }
		    
		   getUsers() { 
			return this._http.get(this._baseurl+ 'getUsers')
			.map((response: Response) =>  response.json());
		   }
		   
		   getTests() { 
			return this._http.get(this._baseurl+ 'admin/getTests')
			.map((response: Response) =>  response.json());
		   }
	   
		  getQuestions() { 
			return this._http.get(this._baseurl+ 'admin/getQuestions')
			.map((response: Response) =>  response.json());
		   }  
	   
	 
	   
		   getSampleTests() { 
			return this._http.get(this._baseurl+ 'admin/getSampleTests')
			.map((response: Response) =>  response.json());
		   }
			
		   getMonths() { 
			return this._http.get(this._baseurl+ 'getMonths')
			.map((response: Response) =>  response.json());
		   }
	   
		   getUser(data) { 
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'User', data, options)
						   .map((response: Response) =>  response.json());
		   }
	   

		   addUsers(user){ 
		   
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'admin/users/add', user, options)
						   .map((response: Response) =>  response.json());
		   } 
		   editUsers(user){ 
		   
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'admin/users/edit', user, options)
						   .map((response: Response) =>  response.json());

		   } 	   
	   

	   
		  getById(data) { 
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'admin/getById', data, options)
						   .map((response: Response) =>  response.json());
		   }
	 
	  
		 

		  getLatestUpdate() { 
			return this._http.get(this._baseurl+ 'admin/getLatestUpdate')
			.map((response: Response) =>  response.json());
		   }
		   
	  
	  
		 getStudents(){
			  return this._http.get(this._baseurl+ 'getStudents')
			.map((response: Response) =>  response.json());
			  
		 } 
	
		 
		 getTestimonial() {
				return this._http.get(this._baseurl + 'admin/getTestimonial')
				.map((response:Response) => response.json());	
			}	

		 requestNotification() {
			  
			  return this._http.get( this._baseurl + 'admin/getNotification')
			  .map((response:Response) => response.json());
			  
		  }	 
		deleteRecords(data){
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'admin/deleteRecords', data, options)
						   .map((response: Response) =>  response.json());
			
		}		 
	    
}
