import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router }  from '@angular/router'; 

@Component({
  selector: 'app-student',
  templateUrl: './admission.component.html',
  providers: [UserService,PagerService,DatePipe, FilterdataPipe]
})
export class AdmissionComponent implements OnInit {
  public studentForm: FormGroup;
  public settings : any;
  public data : any;
  public students: any = new Array();
  public batchList: any = new Array();
  public feeList: any = new Array();
  public courses: any = new Array();
  public user : any;
  public flag: string;
  public response: any;
  public query: any =  new Array();
  public admission: any =  new Array();
  public qualifications: any =  new Array();
  public selectedcourse : any;
  one: boolean =false;
  two: boolean =false;
  id :number;
  constructor( private _fb: FormBuilder, private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe ,  private _router: Router, private route: ActivatedRoute, public datepipe: DatePipe) {}

  ngOnInit() {
    this.flag= 'add';
    this.route.params.subscribe(params => {
				   this.id = params.id;
				});	
	this.user =  JSON.parse(this._users.getUser());
	this.getStudents();
	this.getBatchList();
	this.getCourse();
	this.getQuery();
			this.studentForm = this._fb.group({
				 name: new FormControl('', Validators.required),
				 email: new FormControl(''),
				 father_name: new FormControl(''),
				 phone: new FormControl('', Validators.required),
				 mobile: new FormControl(),
				 city: new FormControl('', Validators.required),
				 address: new FormControl('', Validators.required),
				 dob: new FormControl(''),
				 joining_date: new FormControl('', Validators.required),
				 batch_id: new FormControl('', Validators.required),
				 fee_id: new FormControl('', Validators.required),
				 course: new FormControl('', Validators.required),
                 kit: new FormControl(''),
                 reference: new FormControl(''),
				 qualifications: this._fb.array([
						 this.getQualification()
					  ])
				});     
              }
  
	  getStudents(){
		  this._users.getStudent(this.user)
		 .subscribe(students => this.students = students);
	  }
	  
	   /**
		   * Create form Qualification
		   */
		  private getQualification() {
			return this._fb.group({
			  qualification: [''],
			  year: ['']
			  
			});
		  }
	  
			/**
	   * Add new unit row into form
	   */
	  private addQualification() {
		const control = <FormArray>this.studentForm.controls['qualifications'];
		control.push(this.getQualification());
	  }
	  
	  /**
	   * Remove unit row from form on click delete button
	   */
	  private removeQualification(i: number) {
		const control = <FormArray>this.studentForm.controls['qualifications'];
		control.removeAt(i);
	  }

	 getQuery(){
			 var data = {table : 'query', id : this.id };
			  this._users.getById(data)
			 .subscribe(query => { this.query = query;
			   this.getFeeList(this.query.query_for);
			   this.studentForm.setValue({
							 name: this.query.student_name,
							 email: '',
							 father_name: this.query.father_name,
							 phone: this.query.mobile,
							 mobile: '',
							 address: this.query.address,
							 city: this.query.city,
							 dob: '',
							 joining_date: '',
							 batch_id: '',
							 course: this.query.query_for,
							 fee_id: '',
							 kit: '',
							 reference: '',
                             qualifications: this._fb.array([
								 this.getQualification()
							  ])							 
						});
					  });			
					 } 
	  
		   getBatchList(){
			  this._users.getBatchList(this.user)
			 .subscribe(batchList =>this.batchList = batchList);
		   }
  
		  getFeeList(course_id){
			  let data = { user_id: this.user.id, course_id: course_id };
			  this._users.getFeeByCourse(data)
			 .subscribe(feeList =>this.feeList = feeList);
		   }
		 getCourse(){
		   this._users.getCourse(this.user)
		 .subscribe(courses => this.courses = courses );
	     }  
		   
	    onSaveConfirm(data) {
			       data.qualifications = JSON.stringify(data.qualifications);
				   data.user_id= this.user.id;
				   var reg = this.generate_registration_no();
				   data.registration_no = reg.registration_no;
				   data.series = reg.series;
				   data.query_id = this.query.id;
				   data.joining_date = this.datepipe.transform(data.joining_date, 'yyyy-M-dd');
	               data.dob = this.datepipe.transform(data.dob, 'yyyy-M-dd');
				   this._users.addStudent(data)
				  .subscribe(response => {
					  this.response = response;
					  if(this.response.success){
						  this.flag = 'admission';
						  this.getStudents();
						  //this.admission = data;
						 // this.selectedcourse = data.course;
						 // this.qualifications = JSON.parse(this.admission.qualifications);
						  // if(this.duration(data.fee_id) <= 30) { this.one = true;}
						   //if(this.duration(data.fee_id) >= 60) { this.two = true;}
						 
						   this.toastr.success(this.response.message); 
 						   this._router.navigate(['/students']); 
					  }
					  else{
						  this.toastr.error(this.response.message); 
					  }
				  });  
			  }
   onChange(val){
	   this.getFeeList(val);
   }
   
   show(val){
	   this.flag = val;
   }
   generate_registration_no(){
	  this.user.reg_code;
	  var reg = { registration_no : this.user.reg_code + '-' + 1001, series: 1001 };
	  if(this.students.length > 0 ) {
	  var max = this.students.reduce(function (prev, current) {
				   return (prev.series > current.series) ? prev : current
				});
     reg = { registration_no: this.user.reg_code + '-' + (parseInt(max.series)+ 1), series: parseInt(max.series) + 1 };  		
	  }
	 return reg;
  }
  
  
  print(): void {
			let printContents, popupWin;
			printContents = document.getElementById('print_membership').innerHTML;
			popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
			popupWin.document.open();
			popupWin.document.write(`
			  <html>
				<head>
				  <title>Print</title>
				  <link rel="stylesheet" href="/assets/css/style.css">
				  <link rel="stylesheet" href="/assets/css/style.min.css">
				 <link rel="stylesheet" href="/assets/css/bootstrap.min.css">
				 <link rel="stylesheet" href="/assets/css/font-awesome.min.css">
                 <link rel="stylesheet" href="/assets/css/typography.css">
				 
				</head>
			   <body onload="window.print();window.close()">${printContents}</body>
			  </html>`
			);
			popupWin.document.close();
		}
	
     duration(fee_id){
			for (let obj of this.feeList) {
				if(obj.id == fee_id) {
				return obj.duration;						
				}
              }
             }
	 
}
