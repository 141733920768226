import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { LoaderService } from '../../services/loader.service';
import { ExcelService } from '../../services/excel.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router }  from '@angular/router'; 
import { ToastrService } from 'ngx-toastr';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import { ieltsCalculator } from './result.model';
import { DatePipe } from '@angular/common';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-student',
  templateUrl: './result.component.html',
  providers: [UserService,PagerService, DatePipe, FilterdataPipe]
})
export class ResultComponent implements OnInit {
  public resultForm: FormGroup;
  public response: any;
  public flag: string;
  public user : any;
  public resultStudents : any;
  public model : any = new ieltsCalculator(0, 0, 0, 0);
  public overAllBand : any;
  student  : any;
  row: any;
  id: number;
  peopleFilter : any; 
    // pager object
  pager: any = {};
  perPage:any = 10;
  pagedItems: any[];
  
  constructor(private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe, private route: ActivatedRoute, public _loader: LoaderService, public datepipe: DatePipe, private excelService: ExcelService) {}

  ngOnInit() {
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.getResultStudent();
  }
  
  getResultStudent(){
	  this._users.getResultStudent(this.user)
	 .subscribe(resultStudents => { 
	   this.resultStudents = resultStudents;
	   this.setPage(1);
	  });
  }
  
  setPage(page: number) {
			// get pager object from service
			
			this.pager = this.pagerService.getPager(this.resultStudents.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.resultStudents.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}
		
	
  result(id) {
	  this.flag = 'result';
	  this.resultForm = new FormGroup ({
		 registration_no: new FormControl('', Validators.required),
		 name: new FormControl('', Validators.required),
		 reading: new FormControl('', Validators.required),
		 speaking: new FormControl('', Validators.required),
		 writing: new FormControl('', Validators.required),
		 listening: new FormControl('', Validators.required),
		 overAll: new FormControl('', Validators.required),
		 result_date: new FormControl('', Validators.required)
	  });
	  
	 this._loader.display(true);
		  var data = {table : 'examination', id : id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
	
	       if(this.row.result)
		   {
			  this.model = JSON.parse(this.row.result);
		   }
			
		    var data = {table : 'students', id : this.row.student_id };
		    this._users.getById(data)
				 .subscribe(student =>{
					this.student = student;
			this.resultForm.setValue({
				         registration_no: this.student.registration_no,
						 name: this.student.name,
						 reading: this.model.reading > 0 ? this.model.reading : '' ,
		                 speaking: this.model.speaking > 0 ? this.model.speaking : '',
		                 writing: this.model.writing > 0 ? this.model.writing : '',
		                 listening: this.model.listening > 0 ? this.model.listening : '',
						 overAll: this.row.overAll,
                         result_date: this.row.result_date						 
					});
		          this._loader.display(false);	  
	            });
		 
	  });
  
	  
  }
   show(data){
	   this.flag = data;
	   
   }
 
  
   onSaveConfirm(data) {
	   var result_date = this.datepipe.transform(data.result_date, 'yyyy-M-dd');
	   var examdata = {table : 'examination', id: this.row.id, result : JSON.stringify(this.model), overAll : data.overAll, result_date: result_date };
	   this._loader.display(true);
	   this._users.updateData(examdata)
	  .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this._loader.display(false);
			  this.flag = 'list';
			  this.getResultStudent();
			  this.toastr.success(this.response.message);	  
		  }
		  else{
			  this._loader.display(false);
			  this.toastr.error(this.response.message); 
		  }

	  })  
  }
  
  
  	over_all_band(){
		
		  var data = new ieltsCalculator (this.resultForm.value.reading , this.resultForm.value.speaking ,this.resultForm.value.writing,this.resultForm.value.listening);
		  this.model = data;
		  var total = 0;
		  var tempBand = 0;
		  total = Number(data.listening) + Number(data.reading) + Number(data.writing) + Number(data.speaking);
		  tempBand  = total/4;
		  var integr = Math.floor(tempBand), decimal = tempBand - integr;
				 if(decimal > 0 && decimal < 0.25)
				   {
					 decimal = 0.25;	   
				   }
				 else if (decimal > 0.24 && decimal < 0.75)
				   {
				   decimal = 0.5;	   
				   }
			   else if (decimal > 0.74 && decimal < 1)
		           {
		    	   decimal = 1;	   
			       }
	      this.overAllBand = integr + decimal; 
          this.resultForm.setValue({
				         registration_no: this.student.registration_no,
						 name: this.student.name,
						 reading: data.reading,
		                 speaking: data.speaking,
		                 writing: data.writing,
		                 listening: data.listening,
						 overAll: this.overAllBand,
                         result_date: this.resultForm.value.result_date						 
					});
 		  
	 }
	 
     public exportToExcel() {
				 var ecxelData = this.resultStudents; 
				 ecxelData.forEach(obj => {
					      delete obj['id'];
						  delete obj['user_id'];
						  delete obj['student_id'];
						  delete obj['result'];
						  delete obj['overAll'];
						  delete obj['status'];
						  
				 });
				 this.getResultStudent();
				 this.excelService.exportAsExcelFile(ecxelData, 'result');
	  }
  	 

    public exportToPdf() 
		 { 
		 var data = document.getElementById('contentToConvert'); 
		 html2canvas(data).then(canvas => { 
		 // Few necessary setting options 
		 var imgWidth = 208; 
		 var pageHeight = 295; 
		 var imgHeight = canvas.height * imgWidth / canvas.width; 
		 var heightLeft = imgHeight; 
		 
		 const contentDataURL = canvas.toDataURL('image/png') 
		 let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
		 var position = 0; 
		 pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
		 pdf.save('results.pdf'); // Generated PDF  
		 }); 
	  }	 
  
}
