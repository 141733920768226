import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { LoaderService } from '../../services/loader.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import { ActivatedRoute, Router} from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-student',
  templateUrl: './deposit.component.html',
  providers: [UserService,PagerService, DatePipe, FilterdataPipe]
})
export class DepositComponent implements OnInit {
  public depositForm: FormGroup;
  public students: any = new Array();
  public user : any;
  public response: any;
  public flag: string;
  public student : any;
  row : any;
  id:number;
  constructor(private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe, private _router: Router, public _loader: LoaderService, private route: ActivatedRoute, public datepipe: DatePipe) {}

  ngOnInit() {
	this._loader.display(true);  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.route.params.subscribe(params => {
				   this.id = params.id;
				});
				
	this.depositForm = new FormGroup ({
		 registration_no: new FormControl('', Validators.required),
		 student_name: new FormControl('', Validators.required),
		 batch_name: new FormControl('', Validators.required),
		 start_date: new FormControl('', Validators.required),
		 end_date: new FormControl('', Validators.required),
		 fee: new FormControl('', Validators.required),
		 discount_type: new FormControl(''),
		 discount: new FormControl(''),
		 
		 gst: new FormControl('', Validators.required),
		 amount: new FormControl('', Validators.required)
	  });
	  
	  
	   this._loader.display(true);
		  var data = {table : 'students', id : this.id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			this.serchDetial(this.row.registration_no);
		    this.flag = 'add';			
		    this._loader.display(false);	  
	  });
  }
  
  getStudents(){
	  this._users.getStudent(this.user)
	 .subscribe(students => { 
	       this.students = students;
           });
  }
  onSaveConfirm(data) {
	   this._loader.display(true);
	   data.user_id= this.user.id;
	   
	   //this.calculate_validity(data.fee);
	   data.end_date =  this.datepipe.transform(data.end_date, 'yyyy-M-dd'); //this.calculate_validity(data.fee);
	   data.start_date = this.datepipe.transform(data.start_date, 'yyyy-M-dd');
	   data.valid_upto =   data.end_date;
	   data.student_id = this.student.id;
	   this._users.deposit(data)
	  .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			   this._loader.display(false);
			   this._router.navigate(['/receipts/'+response.data.insertId]);
			 // this.gettransaction();
			  this.toastr.success(this.response.message); 
			  
		  }
		  else{
			  this._loader.display(false);
			  this.toastr.error(this.response.message); 
		  }

	  })
	  
  }
  
  show(flag){
	  this.flag = flag;
  }  
  
  serchDetial(reg){
	  var data = {user_id : this.user.id, registration_no: reg };
	  this._users.getStudentByReg(data)
	 .subscribe(student => { 
	       this.student = student;
		   this.depositForm.setValue({
			             registration_no: this.student.registration_no,
						 student_name: this.student.name,
						 batch_name: this.student.batch_name,
						 start_date: '',
						 end_date: '',
						 fee: '',
						 discount_type : 1,
						 discount : 0,
                         gst : '',
						 amount: ''					 
           }); 
		   }); 
  }
  
  fee_calculate(event){
	       //var gst = Math.round((event.target.value * 18 /118)); 
		   
		   this.depositForm.setValue({
			             registration_no: this.student.registration_no,
						 student_name: this.student.name,
						 batch_name: this.student.batch_name,
						 start_date: this.depositForm.value.start_date,
						 end_date: this.depositForm.value.end_date,
						 fee: event.target.value,
						 discount_type: 1,
                         gst : 	 0,
						 discount: 0,
						 amount: (parseInt(event.target.value))
                         						 
           }); 
	  
  }
  
  gst_apply(event){
	
	      if(event.checked){
				 var amt = Math.round((this.depositForm.value.amount/1.18)); 
		         var gst = this.depositForm.value.amount - amt;
				this.depositForm.setValue({
								 registration_no: this.student.registration_no,
								 student_name: this.student.name,
								 batch_name: this.student.batch_name,
								 start_date: this.depositForm.value.start_date,
						         end_date: this.depositForm.value.end_date,
								 fee: this.depositForm.value.fee,
								 discount_type: this.depositForm.value.discount_type,
								 gst : 	 gst,
								 discount: this.depositForm.value.discount,
								 amount: amt
														 
				   }); 
		   }
		   else {
			                    var discount = this.depositForm.value.discount;
								if(this.depositForm.value.discount_type == 2)
									  {
										discount = Math.round((this.depositForm.value.fee * this.depositForm.value.discount) / 100);
									
									}
			   this.depositForm.setValue({
								
								 registration_no: this.student.registration_no,
								 student_name: this.student.name,
								 batch_name: this.student.batch_name,
								 start_date: this.depositForm.value.start_date,
						         end_date: this.depositForm.value.end_date,
								 fee: this.depositForm.value.fee,
								 discount_type: this.depositForm.value.discount_type,
								 gst : 	 0,
								 discount: this.depositForm.value.discount,
								 amount: (parseInt(this.depositForm.value.fee) - discount)
														 
				   }); 
			   
			   
		   }
	      
	  
  }
   dis_calculate(event){
	          var fee =  this.depositForm.value.fee;
			  var discount = event.target.value;
			
	          if(this.depositForm.value.discount_type == 2)
			  {
			    discount = Math.round((fee * event.target.value) / 100);
           	
			  }
			  var amount = this.depositForm.value.fee - discount;
			  var gst = this.depositForm.value.gst;
			  if(gst > 0) {
				 var gst_amount = Math.round(amount/1.18); 
		          gst = amount - gst_amount; 
				  amount = amount - gst;
			  }
			 
			  
		   this.depositForm.setValue({
			             registration_no: this.student.registration_no,
						 student_name: this.student.name,
						 batch_name: this.student.batch_name,
						 start_date: this.depositForm.value.start_date,
						 end_date: this.depositForm.value.end_date,
						 fee: fee,
						 discount_type: this.depositForm.value.discount_type,
                         gst : gst,
						 discount: event.target.value,
						 amount: amount
                         						 
           }); 
  }
  
  
   discount_cal(){
	   
	          var fee =  this.depositForm.value.fee;
			  var discount = this.depositForm.value.discount;
			 
	          if(this.depositForm.value.discount_type == 2)
			   {
			    discount = Math.round((fee * discount) / 100);
           	
			   }
			  var amount = this.depositForm.value.fee - discount;
			  var gst = this.depositForm.value.gst;
			  if(gst > 0) {
				 var gst_amount = Math.round(amount/1.18); 
		          gst = amount - gst_amount; 
				 amount = amount - gst;
			  }
		   this.depositForm.setValue({
			             registration_no: this.student.registration_no,
						 student_name: this.student.name,
						 batch_name: this.student.batch_name,
						 start_date: this.depositForm.value.start_date,
						 end_date: this.depositForm.value.end_date,
						 fee: fee,
						 discount_type: this.depositForm.value.discount_type,
                         gst : gst,
						 discount: this.depositForm.value.discount,
						 amount: amount
                         						 
           }); 
  }
  
  
  
  calculate_validity(paid){
	 var days = this.student.duration;
	 var fee = this.student.fee;
	 var dayfee = fee/days;  
	 var addday = paid / dayfee;
	 if(this.student.valid_upto !== null) {
		 return this.getdate(this.student.valid_upto, addday);	 
	 }
	 else{
		  return this.getdate(this.student.joining_date, addday);	 
	 }
     
  }
    
	getdate(startDate, numberOfDays) {
			var date = new Date(startDate);
			var newdate = new Date(date);
			newdate.setDate(newdate.getDate() + numberOfDays);
			var dd = newdate.getDate();
			var mm = newdate.getMonth() + 1;
			var y = newdate.getFullYear();
			var someFormattedDate = y + '-' + mm + '-' + dd;
		return 	someFormattedDate;
}
  
}
