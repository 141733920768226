import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router }  from '@angular/router'; 

@Component({
  selector: 'app-student',
  templateUrl: './admission-print.component.html',
  providers: [UserService,PagerService,DatePipe, FilterdataPipe]
})
export class AdmissionPrintComponent implements OnInit {
  public studentForm: FormGroup;
  public settings : any;
  public data : any;
  public student: any = new Array();
  public feeList: any = new Array();
  public courses: any = new Array();
  public user : any;
  public response: any;
  public query: any =  new Array();
  public admission: any =  new Array();
  public qualifications: any =  new Array();
  public selectedcourse : any;
  one: boolean =false;
  two: boolean =false;
  id :number;
  constructor( private _fb: FormBuilder, private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe ,  private _router: Router, private route: ActivatedRoute, public datepipe: DatePipe) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
				   this.id = params.id;
				});	
	this.user =  JSON.parse(this._users.getUser());
	this.getStudents();
	this.getCourse();
  }
	  getStudents(){
		  var data = {table : 'students', id : this.id };
		  this._users.getById(data)
		 .subscribe(admission => { 
		 
		   this.admission = admission;
		   this.selectedcourse = admission.course;
		   this.getFeeList( admission.course);
		   this.qualifications = JSON.parse(this.admission.qualifications);
		   if(this.duration(this.admission.fee_id) <= 30) { this.one = true;}
		   if(this.duration(this.admission.fee_id) >= 60) { this.two = true;}
		   
		 });
	  }
	  
		  getFeeList(course_id){
			  let data = { user_id: this.user.id, course_id: course_id };
			  this._users.getFeeByCourse(data)
			 .subscribe(feeList =>this.feeList = feeList);
		   }
		 getCourse(){
		   this._users.getCourse(this.user)
		 .subscribe(courses => this.courses = courses );
	     }  

  
  print(): void {
			let printContents, popupWin;
			printContents = document.getElementById('print_membership').innerHTML;
			popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
			popupWin.document.open();
			popupWin.document.write(`
			  <html>
				<head>
				  <title>Print</title>
				  <link rel="stylesheet" href="/assets/css/style.css">
				  <link rel="stylesheet" href="/assets/css/style.min.css">
				 <link rel="stylesheet" href="/assets/css/bootstrap.min.css">
				 <link rel="stylesheet" href="/assets/css/font-awesome.min.css">
                 <link rel="stylesheet" href="/assets/css/typography.css">
				 
				</head>
			   <body onload="window.print();window.close()">${printContents}</body>
			  </html>`
			);
			popupWin.document.close();
		}
	
     duration(fee_id){
			for (let obj of this.feeList) {
				if(obj.id == fee_id) {
				return obj.duration;						
				}
              }
             }
	 
}
