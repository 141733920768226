import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../../services/user.service';
import { PagerService } from '../../../services/pager.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-student',
  templateUrl: './setting.component.html',
  providers: [UserService,PagerService]
})
export class SettingComponent implements OnInit {
  public settingForm: FormGroup;
  public user : any;
  public response: any;
  rows : any;
  constructor(private _users: UserService,
              private  toastr: ToastrService
             
			 ) {}

  ngOnInit() {
	  

	this.user =  JSON.parse(this._users.getUser());
	this.getsetting();
	this.settingForm = new FormGroup ({
		 gst_no: new FormControl(''),
		 email: new FormControl(''),
	  });
     }
  
    getsetting(){
	   this._users.getSetting(this.user)
	 .subscribe(rows => { 
	 
	   this.rows = rows;
	   this.settingForm.setValue({
		 gst_no: this.rows.gst_no,
		 email: this.rows.email
	  });
	   
	 });
  
   }

  onSaveConfirm(data) {
	  data.user_id= this.user.id;
	    this._users.updateSetting(data)
	   .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.toastr.success(this.response.message);
			  this.getsetting();
		  }
		  else{
			  this.toastr.error(this.response.message); 
		  }

	  });
  }
}
