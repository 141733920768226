import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-trick',
  templateUrl: './tips-trick.component.html',
  styleUrls: ['./tips-trick.component.css']
})
export class TipsTrickComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
