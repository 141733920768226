import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CoolStorageModule } from 'angular2-cool-storage';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { LoaderService } from './services/loader.service';
import { ExcelService } from './services/excel.service';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatSelectModule, MatCheckboxModule, MatMenuModule } from '@angular/material';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CKEditorModule } from 'ng2-ckeditor';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ProfileComponent } from './front/profile/profile.component';
import { InstituteLoginComponent } from './front/login/institute-login.component';
import { HomeComponent } from './front/home/home.component';
import { HeaderComponent } from './front/layout/header/header.component';
import { FooterComponent } from './front/layout/footer/footer.component';
import { LeftSideBarComponent } from './front/layout/left-side-bar/left-side-bar.component';
import { AboutComponent } from './front/about/about.component';
import { ContactComponent } from './front/contact/contact.component';
import { BlogComponent } from './front/blog/blog.component';
import { TestimonialsComponent } from './front/testimonials/testimonials.component';
import { WhatIsIeltsComponent } from './front/what-is-ielts/what-is-ielts.component';
import { TipsTrickComponent } from './front/tips-trick/tips-trick.component';
import { IeltsReadingComponent } from './front/ielts-reading/ielts-reading.component';
import { IeltsListeningComponent } from './front/ielts-listening/ielts-listening.component';
import { IeltsSpeakingComponent } from './front/ielts-speaking/ielts-speaking.component';
import { IeltsWritingComponent } from './front/ielts-writing/ielts-writing.component';
import { IeltsResourcesComponent } from './front/ielts-resources/ielts-resources.component';
import { IeltsBasicComponent } from './front/ielts-basic/ielts-basic.component';
import { OurAlumniComponent } from './front/our-alumni/our-alumni.component';
import { IeltsFaqComponent } from './front/ielts-faq/ielts-faq.component';
import { StudyVisaComponent } from './front/study-visa/study-visa.component';
import { UserLogoutComponent } from './front/Logout/user-logout.component';
import { StudentComponent } from './front/student/student.component';
import { AdmissionComponent } from './front/student/admission.component';
import { AdmissionPrintComponent } from './front/student/admission-print.component';
import { ReceiptComponent } from './front/receipts/receipt.component';
import { RefundComponent } from './front/refund/refund.component';
import { QueryComponent } from './front/query/query.component';
import { BatchComponent } from './front/settings/batch/batch.component';
import { FeeComponent } from './front/settings/fee/fee.component';
import { CourseComponent } from './front/settings/course/course.component';
import { SettingComponent } from './front/settings/settings/setting.component';
import { DepositComponent } from './front/fees/deposit.component';
import { TransactionComponent } from './front/transaction/transaction.component';
import { EditDepositComponent } from './front/transaction/edit-deposit.component';
import { ExamComponent } from './front/examination/exam.component';
import { ResultComponent } from './front/Results/result.component';
import { FoundationComponent } from './front/how-we-teach-ieilts/foundation.component';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular'

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { PagerService } from './services/pager.service';
import { FilterdataPipe  } from './pipe/filterdata.pipe';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
	BrowserAnimationsModule, 
	LayoutModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
	ReactiveFormsModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
	MatDatepickerModule,
    MatInputModule,
	MatSelectModule,
	MatCheckboxModule,
	MatMenuModule,
    MatNativeDateModule,
	HttpModule,
	CoolStorageModule,

    ToastrModule.forRoot(),
	CKEditorModule,
	Ng2SmartTableModule,
	ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
	
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
	ProfileComponent,
	InstituteLoginComponent,
	HomeComponent,
    AboutComponent,
    ContactComponent,
    BlogComponent,
    TestimonialsComponent,
	HeaderComponent,
	FooterComponent,
    LeftSideBarComponent,
    WhatIsIeltsComponent,
    TipsTrickComponent,
    IeltsReadingComponent,
    IeltsListeningComponent,
    IeltsSpeakingComponent,
    IeltsWritingComponent,
    IeltsResourcesComponent,
    IeltsBasicComponent,
    OurAlumniComponent,
    IeltsFaqComponent,
    StudyVisaComponent,
    StudentComponent,
	AdmissionComponent,
	AdmissionPrintComponent,
	ReceiptComponent,
	RefundComponent,
	UserLogoutComponent,
	QueryComponent,
	FilterdataPipe,
	BatchComponent,
	FeeComponent,
	CourseComponent,
	SettingComponent,
	DepositComponent,
	TransactionComponent,
	EditDepositComponent,
	ExamComponent,
	ResultComponent,
	FoundationComponent
  ],
  providers: [PagerService, LoaderService, ExcelService],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

/*providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],*/
