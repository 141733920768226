import { Injectable, Inject } from '@angular/core';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Http , Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { BASEURL } from '../config/config';

@Injectable()
export class UserService {
    private _baseurl= BASEURL; 
    public get authHeader(): string {
         return `${ this._localStorage.getItem('token') }`;
      }
   constructor(private _http: Http, public _localStorage: CoolLocalStorage){}
		   
	    public setUser(user)
			 {
			   this._localStorage.setItem('user', JSON.stringify(user));
			 }
	 
         public getUser()
          {  
	         return this._localStorage.getItem('user');
          }	
		  
		public setToken(token)
			 {
			   this._localStorage.setItem('token', token);
			 }
	 
	 
         public getToken()
          {  
	         return this._localStorage.getItem('token');
          }	  
		  
        public removeCondidate(data)
          {       
		       
	           let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'removeCondidate', data, options)
						   .map((response: Response) =>  response.json());
          }		
	 
	    public isLogin()
				   {
						var checkStorage = this.getUser();
						if(checkStorage) {
							return true;
						}
						else{
							return false;
						}
				 }
	    public logout()
			  {
			  this._localStorage.removeItem('user');
			  this._localStorage.removeItem('token');
			  
			 }	
	   public getById(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getById', data, options)
						   .map((response: Response) =>  response.json()); 
	    }	


       public updateData(data)
         {     
	           let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'updateData', data, options)
						   .map((response: Response) =>  response.json());
         }		
			 
       public getStudent(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getStudent', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
		
		
	  public getStudentByReg(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getStudentByReg', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
	  public getStudentFee(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getStudentFee', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
		
	   public refundAmount(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'refundAmount', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
		
	   public refundStudent(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'refundStudent', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
		
		
       public addStudent(data)
        {       
	           let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'addStudent', data, options)
						   .map((response: Response) =>  response.json());
        }
		
		
		
		
	   public getQueries(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getQueries', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
		
		
       public addQuery(data)
        {       
	           let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'addQuery', data, options)
						   .map((response: Response) =>  response.json());
        }
		
	  public getBatches(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getBatches', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
      public addBatch(data)
        {       
	           let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'addBatch', data, options)
						   .map((response: Response) =>  response.json());
        }
		
	  public getCourse(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getCourse', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
      public addCourse(data)
        {    
	           let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'addCourse', data, options)
						   .map((response: Response) =>  response.json());
        }	
		
		
	 public getFee(data){
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'getFee', data, options)
							   .map((response: Response) =>  response.json()); 
			}
		
		public getFeeByCourse(data){
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'getFeeByCourse', data, options)
							   .map((response: Response) =>  response.json()); 
			}		
		 public addFee(data)
			{       
				  
				  let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'addFee', data, options)
							   .map((response: Response) =>  response.json());
			}
		
		 public getSetting(data){
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'getSetting', data, options)
							   .map((response: Response) =>  response.json()); 
			}
		
		
		public updateSetting(data)
			{       
				  
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'updateSetting', data, options)
							   .map((response: Response) =>  response.json());
			}
		
		public addExamDate(data)
			{       
				  
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'addExamDate', data, options)
							   .map((response: Response) =>  response.json());
			}
		
	  public getExamStudent(data){
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'examStudents', data, options)
							   .map((response: Response) =>  response.json()); 
			}
		
		public getResultStudent(data){
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'resultStudent', data, options)
							   .map((response: Response) =>  response.json()); 
			}
		
		
		public deposit(data){
				   let headers = new Headers();
							headers.append('Content-Type', 'application/json');
							headers.append('authorization', this.authHeader );
				   let options = new RequestOptions({ headers: headers });
				   return this._http.post(this._baseurl+ 'deposit', data, options)
							   .map((response: Response) =>  response.json()); 
			}
		
		public updateTransaction(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'updateTransaction', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
		
		
		public getTransaction(data){
               let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getTransaction', data, options)
						   .map((response: Response) =>  response.json()); 
	    }
      public getLatestUpdate(){
		   return this._http.get(this._baseurl+ 'getLatestUpdate')
				  .map((response: Response) =>  response.json());
	     }			 
		 
		 
	  public getCities() { 
				  return this._http.get(this._baseurl+ 'getCities')
				  .map((response: Response) =>  response.json());
			   }
			   
	 public getMycCenters() { 
				  return this._http.get(this._baseurl+ 'getMycCenters')
				  .map((response: Response) =>  response.json());
			   }		   
	   
	   
	  public getCity(data) { 
			   let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'City', data, options)
						   .map((response: Response) =>  response.json());
		     }
   
   
	  public getBatchList(data) {
			   let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getBatchList', data, options)
						   .map((response: Response) =>  response.json());
			}
			
			
	  public nodeLogin(user)
			  {  

			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'nodeLogin', user, options)
						   .map((response: Response) =>  response.json());
			 }
	 

     public getTestMonth(data) {
			
			   let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getTestMonth', data, options)
						   .map((response: Response) =>  response.json());
			}
			
     
	 public contactQuery(data){
			   let headers = new Headers({ 'Content-Type': 'application/json' });
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'contact', data, options)
						   .map((response: Response) =>  response.json());
		 
		 }	

	 public getResults(data){
			   let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
			   let options = new RequestOptions({ headers: headers });
			   return this._http.post(this._baseurl+ 'getResults', data, options)
						   .map((response: Response) =>  response.json());
		 }
	 public getTestimonials(){
				  return this._http.get(this._baseurl+ 'getTestimonials')
				  .map((response: Response) =>  response.json()); 
		}

     public deleteRecords(data){
		   let headers = new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('authorization', this.authHeader );
           let options = new RequestOptions({ headers: headers });
           return this._http.post(this._baseurl+ 'deleteRecords', data, options)
		               .map((response: Response) =>  response.json());	
	 }		
}
