import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import {UserService}  from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  providers: [UserService],
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  public mycCenters : any = new Array();
  public mycCenter : any = new Array();
  public response: any;
  public sendText: string = 'Send';
  
  
  constructor(private _user : UserService, private  toastr: ToastrService,) { }

  ngOnInit() {
	  
	  this.getMycCenters();
	  this.contactForm = new FormGroup({
         name: new FormControl('', Validators.required ),
         email: new FormControl('', Validators.required),
		 phone: new FormControl('', Validators.required),
		 subject: new FormControl('', Validators.required),
		 message: new FormControl('', Validators.required)
      });
    }
   
   
   
   getMycCenters(){
	   
	   this._user.getMycCenters()
	   .subscribe(mycCenters => {
	     this.mycCenters = mycCenters;
		 
	     this.getMycCenter(this.mycCenters[0].id);
	   });  
   }
   
   getMycCenter(id){
	   let data = {table : 'myc_centers', id : id };
	   this._user.getById(data)
	   .subscribe(mycCenter => this.mycCenter = mycCenter );  
   }
   
   
   chnageCenters(center) {
	 this.getMycCenter(center);
    }
   
   onClickSubmit(data) {
	  
	      this._user.contactQuery(data)
	     .subscribe(response =>  {
							 this.response = response;
							 if(this.response.success)
								{     
								  this.toastr.success(response.message);
                                  this.contactForm.reset();								  
								}
							   else{
									this.toastr.error(response.message); 	 
							   }
						  });
   }

}
