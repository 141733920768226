import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'filterdata'
})
export class FilterdataPipe implements PipeTransform {
 transform(items: any, filter: any, isAnd: boolean): any {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (isAnd) {
        return items.filter(item =>
            filterKeys.reduce((memo, keyName) =>
                (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === "", true));
      } else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
			  if(keyName == 'date_of_query'){
				  var d = new Date(item[keyName]);
				   var mm = d.getMonth()+1;
				  item[keyName] = d.getFullYear()+ '-'+ mm + '-'+ d.getDate();
			  }
			  console.log(item[keyName]);
			  console.log(filter[keyName]);
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === "";
          });
        });
      }
    } else {
      return items;
    }
  }
		
		
}