import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ProfileComponent } from './front/profile/profile.component';
import { InstituteLoginComponent } from './front/login/institute-login.component';
import { HomeComponent } from './front/home/home.component';
import { HeaderComponent } from './front/layout/header/header.component';
import { LeftSideBarComponent } from './front/layout/left-side-bar/left-side-bar.component';
import { FooterComponent } from './front/layout/footer/footer.component';
import { AboutComponent } from './front/about/about.component';
import { ContactComponent } from './front/contact/contact.component';
import { BlogComponent } from './front/blog/blog.component';
import { TestimonialsComponent } from './front/testimonials/testimonials.component';
import { WhatIsIeltsComponent } from './front/what-is-ielts/what-is-ielts.component';
import { TipsTrickComponent } from './front/tips-trick/tips-trick.component';
import { IeltsReadingComponent } from './front/ielts-reading/ielts-reading.component';
import { IeltsListeningComponent } from './front/ielts-listening/ielts-listening.component';
import { IeltsSpeakingComponent } from './front/ielts-speaking/ielts-speaking.component';
import { IeltsWritingComponent } from './front/ielts-writing/ielts-writing.component';
import { IeltsResourcesComponent } from './front/ielts-resources/ielts-resources.component';
import { IeltsBasicComponent } from './front/ielts-basic/ielts-basic.component';
import { OurAlumniComponent } from './front/our-alumni/our-alumni.component';
import { IeltsFaqComponent } from './front/ielts-faq/ielts-faq.component';
import { StudyVisaComponent } from './front/study-visa/study-visa.component';
import { StudentComponent } from './front/student/student.component';
import { AdmissionComponent } from './front/student/admission.component';
import { AdmissionPrintComponent } from './front/student/admission-print.component';
import { TransactionComponent } from './front/transaction/transaction.component';
import { EditDepositComponent } from './front/transaction/edit-deposit.component';
import { ReceiptComponent } from './front/receipts/receipt.component';
import { RefundComponent } from './front/refund/refund.component';
import { QueryComponent } from './front/query/query.component';
import { BatchComponent } from './front/settings/batch/batch.component';
import { FeeComponent } from './front/settings/fee/fee.component';
import { CourseComponent } from './front/settings/course/course.component';
import { SettingComponent } from './front/settings/settings/setting.component';
import { UserLogoutComponent } from './front/Logout/user-logout.component';
import { DepositComponent } from './front/fees/deposit.component';
import { ExamComponent } from './front/examination/exam.component';
import { ResultComponent } from './front/Results/result.component';
import { FoundationComponent } from './front/how-we-teach-ieilts/foundation.component';


export const routes: Routes = [

  { path: '',  redirectTo: '/home', pathMatch: 'full'  },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'what-is-ielts', component: WhatIsIeltsComponent },
  { path: 'tips-trick', component: TipsTrickComponent },
  { path: 'reading/:page', component: IeltsReadingComponent},
  { path: 'listening/:page', component: IeltsListeningComponent},
  { path: 'speaking/:page', component: IeltsSpeakingComponent},
  { path: 'writing/:page', component: IeltsWritingComponent},
  { path: 'ielts-resource/:page', component: IeltsResourcesComponent},
  { path: 'our-alumni', component: OurAlumniComponent},
  { path: 'ielts-faq', component: IeltsFaqComponent},
  { path: 'study-visa', component: StudyVisaComponent},
  { path: 'login', component: InstituteLoginComponent},
  { path: 'students', component: StudentComponent},
  { path: 'admission/:id', component: AdmissionComponent},
  { path: 'student-print/:id', component: AdmissionPrintComponent},
  { path: 'receipts/:id', component: ReceiptComponent},
  { path: 'receipts', component: ReceiptComponent},
  { path: 'refund/:id', component: RefundComponent},
  { path: 'refund', component: RefundComponent},
  { path: 'query', component: QueryComponent },
  { path: 'batches', component: BatchComponent },
  { path: 'fee', component: FeeComponent },
  { path: 'setting', component: SettingComponent },
  { path: 'transaction', component: TransactionComponent },
  { path: 'edit-transaction/:id', component: EditDepositComponent },
  { path: 'fee-deposit/:id', component: DepositComponent },
  { path: 'fee-deposit', component: DepositComponent },
  { path: 'examination', component: ExamComponent },
  { path: 'examination/:id', component: ExamComponent },
  { path: 'results', component: ResultComponent },
  { path: 'foudation', component: FoundationComponent },
  { path: 'course', component: CourseComponent},
  { path: 'logout', component: UserLogoutComponent },
  
 
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'admin-login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
    {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: 'Profile Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
	  {
        path: 'users',
        loadChildren: './views/users/users.module#UsersModule'
      },
	  {
        path: 'pages',
        loadChildren: './views/pages/pages.module#PagesModule'
      },
      {
        path: 'tests',
        loadChildren: './views/tests/tests.module#TestsModule'
      },
	   {
        path: 'questions',
        loadChildren: './views/questions/question.module#QuestionModule'
      },
      {
        path: 'buttons',
        loadChildren: './views/buttons/buttons.module#ButtonsModule'
      },
      {
        path: 'charts',
        loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'icons',
        loadChildren: './views/icons/icons.module#IconsModule'
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'theme',
        loadChildren: './views/theme/theme.module#ThemeModule'
      },
      {
        path: 'widgets',
        loadChildren: './views/widgets/widgets.module#WidgetsModule'
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
