import { Component, OnInit} from '@angular/core';
import { UserService } from '../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';



@Component({
  selector: 'my-app',
  template: '',
  providers: [UserService]
})

export class UserLogoutComponent implements OnInit {

  constructor(private _users: UserService, private router: Router) {}
  ngOnInit() {
    this._users.logout();
    this.router.navigate(['/login']);
  }
}
