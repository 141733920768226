import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ielts-faq',
  templateUrl: './ielts-faq.component.html',

})
export class IeltsFaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
