import { Component, OnInit } from '@angular/core';
import { ieltsCalculator } from './left-side-bar.model';
 
@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.css']
})
export class LeftSideBarComponent implements OnInit {
 
   public model: any = ieltsCalculator;
   public overAll : any;
  constructor() { }

  ngOnInit() {
  }
  
    calculate(){
		var data= this.model;
		this.over_all_band(data);
	}
  
  
  
  	over_all_band(data){
		  var total = 0;
		  var tempBand = 0;
		  total = Number(data.listening) + Number(data.reading) + Number(data.writing) + Number(data.speaking);
		  tempBand  = total/4;
		  var integr = Math.floor(tempBand), decimal = tempBand - integr;
				 if(decimal > 0 && decimal < 0.25)
				   {
					 decimal = 0.25;	   
				   }
				 else if (decimal > 0.24 && decimal < 0.75)
				   {
				   decimal = 0.5;	   
				   }
			   else if (decimal > 0.74 && decimal < 1)
		           {
		    	   decimal = 1;	   
			       }
	      this.overAll = integr + decimal;   
	 }   

}
