import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { LoaderService } from '../../services/loader.service';
import { ExcelService } from '../../services/excel.service';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas'; 
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  providers: [UserService,PagerService, DatePipe, FilterdataPipe]
})
export class StudentComponent implements OnInit {
  public studentForm: FormGroup;
  public settings : any;
  public data : any;
  public students: any = new Array();
  public batchList: any = new Array();
  public feeList: any = new Array();
  public courses: any = new Array();
  public filterData: any = new Array();
  public user : any;
  public response: any;
  public deletedSelected: any = new Array();
  public qualifications: any =  new Array();
  public flag: string;
  private allItems: any[];
  public row : any;
  peopleFilter : any; 
    // pager object
   pager: any = {};
   perPage:any = 10;

    // paged items
  pagedItems: any[];
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really <b>sure</b> you want to do this?';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  
  name : string;
  father : string;
  city : string;
  roll_no : number;
  phone : number;
  
  constructor(private _fb: FormBuilder, private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe,  public _loader: LoaderService, private excelService: ExcelService, public datepipe: DatePipe ) {}

  ngOnInit() {
	this._loader.display(true);  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.getStudents();
	this.getBatchList();
	this.getCourse();
	this._loader.display(false); 
	
	this.studentForm = new FormGroup ({
		 name: new FormControl('', Validators.required),
		 email: new FormControl('', Validators.email),
		 father_name: new FormControl(''),
		 phone: new FormControl('', Validators.required),
		 mobile: new FormControl(),
		 city: new FormControl('', Validators.required),
		 address: new FormControl('', Validators.required),
		 dob: new FormControl(''),
		 joining_date: new FormControl('', Validators.required),
		 course: new FormControl('', Validators.required),
		 batch_id: new FormControl('', Validators.required),
		 fee_id: new FormControl('', Validators.required),
		 kit: new FormControl(''),
		 reference: new FormControl(''),
		 qualifications: this._fb.array([
						 this.getQualification()
					        ])
		 
	  });
  }
  
	   keyPress(event: any) {
		const pattern = /[0-9\+\-\ ]/;

		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
		  event.preventDefault();
		}
	  }
  
	  getStudents(){
		  this._users.getStudent(this.user)
		 .subscribe(students => { 
			   this.students = students;
			   this.filterData = this.students;
			   this.setPage(1); });
	  } 
  
	   getBatchList(){
		  this._users.getBatchList(this.user)
		 .subscribe(batchList =>this.batchList = batchList);
	  }
  
	  getFeeList(course_id){
			  let data = { user_id: this.user.id, course_id: course_id };
			  this._users.getFeeByCourse(data)
			 .subscribe(feeList =>this.feeList = feeList);
		   }
  
	   getCourse(){
		   this._users.getCourse(this.user)
		 .subscribe(courses => this.courses = courses );
	   }
   
		refresh(){
		  this.setPage(1);
		}
  
      setPage(page: number) {
			// get pager object from service
			
			this.pager = this.pagerService.getPager(this.filterData.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.filterData.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}
		
		
	  onDelete(id){
			 this.deletedSelected.push(id);
			 this.deleteSelected();
	  }

   //checkbox function
    checkbox(check, id) {
		if( check.target.checked)
		  {
		   this.deletedSelected.push(id);
		  }
		else{
			const index: number = this.deletedSelected.indexOf(id);
				if (index !== -1) {
					this.deletedSelected.splice(index, 1);
				}   
		}
    }	
	 
  deleteSelected(){
		if(this.deletedSelected.length > 0)
		{   this._loader.display(true);
	        var data = {table: 'students', id: this.deletedSelected, status : 3 };
			this._users.updateData(data)
			.subscribe(response => {
			this.response = response;
			if(this.response.success)
			{   
		        this._loader.display(false);
				this.toastr.success("Records have been deleted.");
			    this.getStudents();
				this.deletedSelected = new Array();
			}
			else{
				this._loader.display(false);
				this.toastr.error("Something wrong, Please try again!");
			}
			});
			
		}
		else{
			this.toastr.error("Please select at list one record!")
		}
		
	}

	  onSaveConfirm(data) {
		   this._loader.display(true);
		   data.qualifications = JSON.stringify(data.qualifications);
		   data.user_id= this.user.id;
		   data.joining_date = this.datepipe.transform(data.joining_date, 'yyyy-M-dd');
		   data.dob = this.datepipe.transform(data.dob, 'yyyy-M-dd');
		   var reg = this.generate_registration_no();
		   data.registration_no = reg.registration_no;
		   data.series = reg.series;
		   this._users.addStudent(data)
		  .subscribe(response => {
			  this.response = response;
			  if(this.response.success){
				  this.studentForm.reset();
				  this._loader.display(false);
				  this.show('list');
				  this.getStudents();
				  this.toastr.success(this.response.message);  
			  }
			  else{
				  this._loader.display(false);
				  this.toastr.error(this.response.message); 
			  }

		  })  
	  }
	  
   onChange(val){
	   this.getFeeList(val);
   }
  
   edit(id){
	      this._loader.display(true);
		  this.studentForm.reset();
		  var data = {table : 'students', id : id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			this.setQualification(JSON.parse(this.row.qualifications));
			this.studentForm.setValue({
						 name: this.row.name,
						 email: this.row.email,
						 father_name: this.row.father_name,
						 phone: this.row.phone,
						 mobile: this.row.mobile,
						 address: this.row.address,
						 city: this.row.city,
						 dob: this.row.dob,
						 joining_date: this.row.joining_date,
						 batch_id: this.row.batch_id,
						 course: this.row.course,
                         fee_id: this.row.fee_id,
                         kit: this.row.kit,
                         reference: this.row.reference,
                         qualifications: JSON.parse(this.row.qualifications)	 
					}); 
		 this._loader.display(false);
		 this.flag = 'edit';
		 });
	  }
	  
	 onUpdate(data){
		   data.id = this.row.id;
		   data.table = 'students';
		   data.qualifications = JSON.stringify(data.qualifications);
		   data.joining_date = this.datepipe.transform(data.joining_date, 'yyyy-M-dd');
	       data.dob = this.datepipe.transform(data.dob, 'yyyy-M-dd');
		   this._users.updateData(data)
		   .subscribe(response => {
			  this.response = response;
			  if(this.response.success){
				  this.toastr.success(this.response.message);
				  this.studentForm.reset();
				  this.getStudents();
				  this.show('list');
			  }
			  else{
				  this.toastr.error(this.response.message); 
			  }
		  });
	  }  
	  
 
  
  show(flag){
	  this.flag = flag;
  }
  
   searchData(){
	   this.filterData = this.pipe.transform(this.students, this.peopleFilter, false);
       this.setPage(1);  	  
  }
  
  search(filter){
	  this.peopleFilter = {};
	  if(filter == 'roll_no' ) this.peopleFilter = {'registration_no': this.roll_no };
	  if(filter == 'name' )    this.peopleFilter = {'name': this.name };
	  //if(filter == 'father' )  this.peopleFilter = {'father_name':  this.father };
	  if(filter == 'phone' )   this.peopleFilter = {'phone': this.phone };
	  if(filter == 'city' )    this.peopleFilter = {'city': this.city };
	  this.searchData();
  }
  
     /**
		   * Create form Qualification
		   */
		  private getQualification() {
			return this._fb.group({
			  qualification: [''],
			  year: ['']
			  
			});
		  }
		  
		   private setQualification(data) {
			     
			     const control = <FormArray>this.studentForm.controls['qualifications'];
				 if(data){
				 if(control.length > data.length) 
				   { 
			          
					  this.studentForm.removeControl('qualifications');
					  this.studentForm.addControl('qualifications', this._fb.array([
						 this.getQualification()
					        ]));
						  for (var _i = 1; _i < data.length; _i++) {
							  (<FormArray>this.studentForm.controls.qualifications).push(this.getQualification()); 		 
						  } 	
					  	 
				  }
				  else{ 
						  for (var _i = 0; _i < data.length; _i++) {
							 if(control.length < data.length) { (<FormArray>this.studentForm.controls.qualifications).push(this.getQualification()); }			 
						  } 	
					   	  
				  }
				 }
			     
		  }
	  
			/**
	   * Add new unit row into form
	   */
	  private addQualification() {
		const control = <FormArray>this.studentForm.controls['qualifications'];
		control.push(this.getQualification());
	  }
	  
	  /**
	   * Remove unit row from form on click delete button
	   */
	  private removeQualification(i: number) {
		const control = <FormArray>this.studentForm.controls['qualifications'];
		control.removeAt(i);
	  }
  
  
   generate_registration_no(){
	  this.user.reg_code;
	  var reg = { registration_no : this.user.reg_code + '-' + 1001, series: 1001 };
	  if(this.students.length > 0 ) {
	  var max = this.students.reduce(function (prev, current) {
				   return (prev.series > current.series) ? prev : current
				});
     reg = { registration_no: this.user.reg_code + '-' + (parseInt(max.series)+ 1), series: parseInt(max.series) + 1 };  		
	  }
	 return reg; 
  }
   
  
	   exportToExcel() {
				 var ecxelData = this.filterData; 
				 ecxelData.forEach(obj => {
						  delete obj['id'];
						  delete obj['user_id'];
						  delete obj['batch_id'];
						  delete obj['fee_id'];
						  delete obj['series'];
						  delete obj['kit'];
						  
				 });
				 this.getStudents();
				 this.excelService.exportAsExcelFile(ecxelData, 'student');
	  }
  
  
	 public exportToPdf() 
		 { 
		 var data = document.getElementById('contentToConvert'); 
		 html2canvas(data).then(canvas => { 
		 // Few necessary setting options 
		 var imgWidth = 208; 
		 var pageHeight = 295; 
		 var imgHeight = canvas.height * imgWidth / canvas.width; 
		 var heightLeft = imgHeight; 
		 
		 const contentDataURL = canvas.toDataURL('image/png') 
		 let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
		 var position = 0; 
		 pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
		 pdf.save('students.pdf'); // Generated PDF  
		 }); 
	  }
	  
	 print(): void {
			let printContents, popupWin;
			printContents = document.getElementById('contentToConvert').innerHTML;
			popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
			popupWin.document.open();
			popupWin.document.write(`
			  <html>
				<head>
				  <title>Print</title>
				  <link rel="stylesheet" href="/assets/css/style.css">
				  <link rel="stylesheet" href="/assets/css/style.min.css">
				 <link rel="stylesheet" href="/assets/css/bootstrap.min.css">
				 <link rel="stylesheet" href="/assets/css/font-awesome.min.css">
                 <link rel="stylesheet" href="/assets/css/typography.css">
				</head>
			   <body onload="window.print();window.close()">${printContents}</body>
			  </html>`
			);
			popupWin.document.close();
		}  
	
}
