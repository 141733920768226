import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  providers : [AdminService],
})
export class LoginComponent {
	
  public loginForm: FormGroup;
  public response: any; 
  
  constructor( private _router: Router, private _admin: AdminService, private  toastr: ToastrService) {}
  
   ngOnInit() {
	  
  this.loginForm = new FormGroup ({
       email:    new FormControl('', Validators.required),
       password: new FormControl('', Validators.required)	   
  }); 
	  
  }
  
  login(user){
	  
	  this._admin.login(user)
	     .subscribe(response => {
         		 this.response = response;
				 
				     if(this.response.success)
					    { 
					   this._admin.setSession('admin');
					   this._router.navigate(['/dashboard']);
					    }
					   else{
						  this.toastr.error(response.message); 
					   }
		       }
			 );
  }
  
}