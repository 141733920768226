import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router }  from '@angular/router'; 

@Component({
  selector: 'app-ielts-reading',
  templateUrl: './ielts-reading.component.html',
  styleUrls: ['./ielts-reading.component.css']
})
export class IeltsReadingComponent implements OnInit {
  
   page :string;
  constructor( private _router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
	  
	    	this.route.params.subscribe(params => {
				   this.page = params.page;
				  
				});
				
  }

}
