import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { ExcelService } from '../../services/excel.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-student',
  templateUrl: './transaction.component.html',
  providers: [UserService,PagerService, FilterdataPipe]
})
export class TransactionComponent implements OnInit {
  public receiptForm: FormGroup;
  public settings : any;
  public data : any;
  public transaction: any = new Array();
  public filterData: any = new Array();
  public user : any;
  public response: any;
  public deletedSelected: any = new Array();
  public day_start: any = new Date();
  public flag: string;
  private allItems: any[];
  private fee_total : number;
  private gst_total : number;
  private total : number;
  peopleFilter : any; 
    // pager object
   pager: any = {};
   perPage:any = 10;

    // paged items
  pagedItems: any[];
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really <b>sure</b> you want to do this?';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  
  reg_no : string;
  name : string;
  
  constructor(private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe, private excelService: ExcelService) {
  }
  
  compareDates(endDate){
		   return new Date(endDate) > new Date(this.day_start);
		}

  ngAfterContentChecked()  {
	this.fee_total =    this.filterData.reduce((sum, c) => sum + parseInt(c.fee), 0);
	this.gst_total =    this.filterData.reduce((sum, c) => sum + ((c.status !=2 ) ? parseInt(c.gst) : 0), 0);
	this.total     =    this.filterData.reduce((sum, c) => sum + parseInt(c.amount), 0);
}
  ngOnInit() {
	  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.getTransaction();
	
	this.receiptForm = new FormGroup ({
		 name: new FormControl('', Validators.required),
		 father_name: new FormControl('', Validators.required),
		 phone: new FormControl('', Validators.required),
		 mobile: new FormControl(),
		 city: new FormControl('', Validators.required),
		 address: new FormControl('', Validators.required),
		 dob: new FormControl('', Validators.required),
		 joining_date: new FormControl('', Validators.required),
		 image: new FormControl()
	  });
  }
  
  
  
  getTransaction(){
	  this._users.getTransaction(this.user)
	 .subscribe(transaction => { 
	       this.transaction = transaction;
		   this.filterData = this.transaction;
           this.setPage(1);		   });
  
  }
  
    refresh(){
	  this.setPage(1);
    }
  
   setPage(page: number) {
			// get pager object from service
			
			this.pager = this.pagerService.getPager(this.filterData.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.filterData.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}
		
		
	  onDelete(id){
			 this.deletedSelected.push(id);
			 this.deleteSelected();
	  }

   //checkbox function
    checkbox(check, id) {
		
		if( check.target.checked)
		  {
		   this.deletedSelected.push(id);
		  }
		else{
			const index: number = this.deletedSelected.indexOf(id);
				if (index !== -1) {
					this.deletedSelected.splice(index, 1);
				}   
		}
    }	
	 
  deleteSelected(){
		if(this.deletedSelected.length > 0)
		{   
	        var data = {table: 'transaction', id: this.deletedSelected, status: 3 };
			this._users.updateData(data)
			.subscribe(response => {
			this.response = response;
			if(this.response)
			{
				this.toastr.success("Records have been deleted.");
			    this.getTransaction();
				this.deletedSelected = new Array();
			}
			else{
				
				this.toastr.error("Something wrong, Please try again!");
			}
			});
			
		}
		else{
			this.toastr.error("Please select at list one record!")
		}
		
	}

  onSaveConfirm(data) {
	   data.user_id= this.user.id;
	   this._users.addStudent(data)
	  .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this.show('list');
			  this.getTransaction();
			  this.toastr.success(this.response.message); 
		  }
		  else{
			  
			  this.toastr.error(this.response.message); 
		  }

	  })
	  
  }
  
	  show(flag){
		  this.flag = flag;
	  }
	  
	   searchData(){
		   this.filterData =   this.pipe.transform(this.transaction, this.peopleFilter, false);
		   this.setPage(1);  	  
	  }
	  
	  search(filter){
		  this.peopleFilter = {};
		  if(filter == 'reg_no' ) this.peopleFilter = {'registration_no': this.reg_no };
		  if(filter == 'name' )    this.peopleFilter = {'student_name': this.name };
		  this.searchData();
	  }
  
     public exportToExcel() {
				 var ecxelData = this.filterData; 
				 ecxelData.forEach(obj => {
					      delete obj['id'];
						  delete obj['user_id'];
						  delete obj['student_id'];
						  delete obj['status']; 
						  
				 });
				 this.getTransaction();
				 if(this.transaction.length > 0) {
				 this.excelService.exportAsExcelFile(ecxelData, 'receipt');
				 }
				 else {
					this.toastr.error("No record found.");
				}
	  }
  	 

     public exportToPdf() 
		 { 
				if(this.transaction.length > 0) {
				 var data = document.getElementById('contentToConvert'); 
				 html2canvas(data).then(canvas => { 
				 // Few necessary setting options 
				 var imgWidth = 208; 
				 var pageHeight = 295; 
				 var imgHeight = canvas.height * imgWidth / canvas.width; 
				 var heightLeft = imgHeight; 
				 
				 const contentDataURL = canvas.toDataURL('image/png') 
				 let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
				 var position = 0; 
				 pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
				 pdf.save('receipt.pdf'); // Generated PDF  
				 }); 
				}
				else {
					this.toastr.error("No record found.");
				}
		 }
  
  
}
