import { Component, OnInit } from '@angular/core';
import { UserService    } from '../../services/user.service';
import { PagerService } from '../../services/pager.service';
import { LoaderService } from '../../services/loader.service';
import { ExcelService } from '../../services/excel.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router }  from '@angular/router'; 
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FilterdataPipe  } from '../../pipe/filterdata.pipe';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-student',
  templateUrl: './exam.component.html',
  providers: [UserService,PagerService, DatePipe, FilterdataPipe]
})
export class ExamComponent implements OnInit {
  public examForm: FormGroup;
  public response: any;
  public flag: string;
  public user : any;
  public examStudents : any;
  public filterData : any;
  row:any;
  id: number;
  peopleFilter : any; 
    // pager object
  pager: any = {};
  perPage:any = 10;
  pagedItems: any[];
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really <b>sure</b> you want to do this?';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  public deletedSelected: any = new Array();
  
  constructor(private _users: UserService, private  toastr: ToastrService,  private pagerService: PagerService, private pipe: FilterdataPipe, private route: ActivatedRoute, public _loader: LoaderService,  public datepipe: DatePipe,  private excelService: ExcelService) {}

  ngOnInit() {
	  
	this.flag ='list';
	this.user =  JSON.parse(this._users.getUser());
	this.route.params.subscribe(params => {
				   this.id = params.id;
				});
				
    				
		
    if(this.id != undefined)
	{
	 this.examForm = new FormGroup ({
		 registration_no: new FormControl('', Validators.required),
		 name: new FormControl('', Validators.required),
		 exam_date: new FormControl('', Validators.required),
		 
	  });
	  
	 this._loader.display(true);
		  var data = {table : 'students', id : this.id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			this.examForm.setValue({
				         registration_no: this.row.registration_no,
						 name: this.row.name,
                         exam_date: ''						 
					});
		  this.flag = 'add';			
		  this._loader.display(false);	  
	  });
    }
	this.getExamStudent();
  }
  
  getExamStudent(){
	  this._users.getExamStudent(this.user)
	 .subscribe(examStudents => { 
	   this.examStudents = examStudents;
	   this.filterData = this.examStudents;
	   this.setPage(1);
	  });
  }
  
  setPage(page: number) {
			// get pager object from service
			
			this.pager = this.pagerService.getPager(this.filterData.length, page, parseInt(this.perPage));
		
			// get current page of items
			this.pagedItems = this.filterData.slice(this.pager.startIndex, this.pager.endIndex + 1);
		
		}
		
		
	  onDelete(id){
			 this.deletedSelected.push(id);
			 this.deleteSelected();
	  }

   //checkbox function
    checkbox(check, id) {
		
		if( check.target.checked)
		  {
		   this.deletedSelected.push(id);
		  }
		else{
			const index: number = this.deletedSelected.indexOf(id);
				if (index !== -1) {
					this.deletedSelected.splice(index, 1);
				}   
		}
    }	
	 
  deleteSelected(){
	    this._loader.display(true);
		if(this.deletedSelected.length > 0)
		{   
	        var data = {table: 'students', id: this.deletedSelected };
			this._users.deleteRecords(data)
			.subscribe(response => {
			this.response = response;
			if(this.response)
			{   
		        this._loader.display(false);
				this.toastr.success("Records have been deleted.");
			    this.getExamStudent();
				this.deletedSelected = new Array();
			}
			else{
				this._loader.display(false);
				this.toastr.error("Something wrong, Please try again!");
			}
			});
			
		}
		else{
			this.toastr.error("Please select at list one record!");
		}
		
	}
  
  show() {
	  
	  this.flag ='list';
  }
  
  
   onSaveConfirm(data) {
	   this._loader.display(true);
	   data.user_id    = this.user.id;
	   data.student_id = this.row.id;
	   data.exam_date = this.datepipe.transform(data.exam_date, 'yyyy-M-dd')
	   this._users.addExamDate(data)
	  .subscribe(response => {
		  this.response = response;
		  if(this.response.success){
			  this._loader.display(false);
			  this.flag = 'list';
			  this.getExamStudent();
			  this.toastr.success(this.response.message);	  
		  }
		  else{
			  this._loader.display(false);
			  this.toastr.error(this.response.message); 
		  }

	  })  
  }
  
  sendSms(id , exam_date){
	  var examdate = this.datepipe.transform(exam_date, 'dd-MM-yyyy');
	  this._loader.display(true);
		  var data = {table : 'students', id : id };
		  this._users.getById(data)
		 .subscribe(row =>{
			this.row = row;
			// add functionality to send sms  
		  	
		  this._loader.display(false);	
          this.toastr.success('Message send successfully.');		  
	  });
  }
  
   public exportToExcel() {
				 var ecxelData = this.filterData; 
				 ecxelData.forEach(obj => {
					      delete obj['id'];
						  delete obj['user_id'];
						  delete obj['student_id'];
						  delete obj['result'];
						  delete obj['overAll'];
						  delete obj['status'];
						  
				 });
				 this.getExamStudent();
				 if(this.examStudents > 0) {
				 this.excelService.exportAsExcelFile(ecxelData, 'result');
				 }
				 else {
					this.toastr.error("No record found.");
				}
	  }
  	 

    public exportToPdf() 
		 { 
				if(this.examStudents > 0) {
				 var data = document.getElementById('contentToConvert'); 
				 html2canvas(data).then(canvas => { 
				 // Few necessary setting options 
				 var imgWidth = 208; 
				 var pageHeight = 295; 
				 var imgHeight = canvas.height * imgWidth / canvas.width; 
				 var heightLeft = imgHeight; 
				 
				 const contentDataURL = canvas.toDataURL('image/png') 
				 let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
				 var position = 0; 
				 pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
				 pdf.save('examination.pdf'); // Generated PDF  
				 }); 
				}
				else {
					this.toastr.error("No record found.");
				}
		 }
          		 
  
}
