import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './institute-login.component.html',
  styleUrls: ['./institute-login.component.scss'],
  providers : [UserService],
})
export class InstituteLoginComponent implements OnInit {
  
  public loginForm : FormGroup;
  public response : any;
  constructor( private _router : Router, private _users: UserService, private  toastr: ToastrService) { }
  ngOnInit() {
	  
	  this.loginForm = new FormGroup ({
		 email: new FormControl('', Validators.required),
         password: new FormControl('', Validators.required)		  
	  });
  }
  
  
  
      login(user) {
	   this._users.nodeLogin(user)
	     .subscribe(response => {
         		 this.response = response;
				 
				     if(this.response.success)
					    { 
					   this._users.setUser(this.response.data);
					   this._users.setToken(this.response.token);
					   this._router.navigate(['/profile']);
					    }
					   else{
						  this.toastr.error(response.message); 
					   }
		       }
			 );   
  }

}
